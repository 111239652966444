export const readerTypes = [
  {
    title: "Truck reader",
    key: "TruckReader",
  },
  {
    title: "Portable reader",
    key: "PortableReader",
  },
  {
    title: "Desktop reader",
    key: "DesktopReader",
  },
  {
    title: "Portable reader connect",
    key: "PortableReaderConnect",
  },
  {
    title: "Unknown",
    key: "Unknown",
  },
];

export enum DeviceConst {
  Details = "details",
  History = "history",
  Failed = "failed",
  Completed = "completed",
  OutStanding = "outstanding",
}
